export const periodicityEnum = {
  DAYS: 'Day/s',
  WEEKS: "Week/s",
  SPRINTS: 'Sprint/s',
  MONTHS: 'Month/s',
  OBJECTIVES: 'Objective/s',
}

export const contractsEnum = {
  TIME_AND_MATERIALS: "Time and materials",
  FIXED_PER_MONTH: "Fixed per month",
  BY_MILESTONE: "By milestone"
}

export const BusinessUnit = {
  SOFTWARE_ENGINEERING: "SOFTWARE ENGINEERING",
  PROFESSIONAL_SERVICES: "PROFESSIONAL SERVICES",
  CONSULTING: "CONSULTING",
  PAYROLL_SERVICES: "PAYROLL SERVICES",
}

export const billingStateEnum = {
  PENDING: 'PENDING',
  TO_REVIEW: 'TO REVIEW',
  READY_TO_BILL: "READY TO BILL",
  INVOICED: "INVOICED",
  PAID: "PAID",
  OVERDUE: "OVER DUE",
  RESCHEDULED: 'RESCHEDULED',
  CANCELLED: "CANCELLED",
  PARTIALLY_PAID: "PARTIALLY PAID"
}

export const BillingPaymentMethod = {
  CASH: 'CASH',
  TRANSFER: 'TRANSFER',
  TAX_WITHHOLDING: 'TAX WITHHOLDING',
  COMPENSATION: 'COMPENSATION'
}

export const employmentContractsEnum = {
  FULLTIME: "fulltime",
  PARTIME: "partime",
  DEMAND: "demand"
}

export const ContractTypeOptions = [
  { value: employmentContractsEnum.FULLTIME, label: 'FULLTIME' },
  { value: employmentContractsEnum.PARTIME, label: 'PART-TIME' },
  { value: employmentContractsEnum.DEMAND, label: 'ON DEMAND' },
];

export const employmentStatusEnum = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const conceptTypeEnum = {
  DISCOUNT: 'Discount',
  BONUS: "Bonus",
  ADDITIONAL_PRODUCT_SERVICE: "Additional product / service",
  COMPENSATION: "Compensation",
}

export const ContractConceptType = {
  MONTHLY: 'MONTHLY',
  EXTRAORDINARY: 'EXTRAORDINARY',
  BENEFIT: 'BENEFIT',
}

export const EmployeeRelationshipType = {
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
  CONTRACTOR_LONG_TERM_MVA: 'CONTRACTOR_LONG_TERM_MVA',
}

export const CalculationBasis = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  REGISTERED_HOURS: 'REGISTERED_HOURS'
}

export const ConceptAmountType = {
  VALUE: 'VALUE',
  PERCENTAGE: 'PERCENTAGE',
}

export const HourPeriodicity = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
}

export const TreeDonationStatusType = {
  PENDING: "pending",
  DONATED: "donated"
}

export const DeliveryManagerQueryOptions = {
  ALL: 'ALL',
  NOT_SET: 'NOT_SET',
}

export const PayrollType = {
  SALARY: 'SALARY',
  FINAL: 'FINAL',
  BONUS: 'BONUS',
}

export const PayrollStatus = {
  PENDING: 'PENDING',
  READY_TO_PAY: 'READY_TO_PAY',
  PAID: 'PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  TO_REVIEW: 'TO_REVIEW',
  ERROR: 'ERROR',
}

export const PayrollPaidStatus = [PayrollStatus.READY_TO_PAY, PayrollStatus.PARTIALLY_PAID, PayrollStatus.PAID];

export const TimesheetSyncStatus = {
  SYNCING: 'SYNCING',
  SYNCED: 'SYNCED',
  ERROR: 'ERROR',
}

export const PolicyType = {
  SOURCING: 'SOURCING',
  ENGAGEMENT: 'ENGAGEMENT',
  REFERER: 'REFERER',
  HR_LEAD: 'HR_LEAD'
}

export const PolicyRecipientType = {
  REFERER: 'REFERER',
  APPLICANT_SOURCE: 'APPLICANT_SOURCE',
  SOURCERS: 'SOURCERS',
  ENGAGERS: 'ENGAGERS',
  HR_LEAD: 'HR_LEAD'
}

export const Seniority = {
  JUNIOR: 'JUNIOR',
  SSR: 'SSR',
  SENIOR: 'SENIOR'
}

export const seniorityOptions = [
  { value: Seniority.JUNIOR, label: 'JUNIOR' },
  { value: Seniority.SSR, label: 'SSR' },
  { value: Seniority.SENIOR, label: 'SENIOR' },
];

export const CommissionStatus = {
  PENDING: 'PENDING',
  ON_REVIEW: 'ON_REVIEW',
  READY_TO_PAY: 'READY_TO_PAY',
  SENT_TO_PAYROLL: 'SENT_TO_PAYROLL',
  PAID: "PAID",
  PARTIALLY_PAID: "PARTIALLY_PAID",
}

export const CommissionPaidStatus = [
  CommissionStatus.READY_TO_PAY,
  CommissionStatus.SENT_TO_PAYROLL,
  CommissionStatus.PARTIALLY_PAID,
  CommissionStatus.PAID
];

export const PolicyPaymentDate = {
  APPLICANT_START_DATE: 'APPLICANT_START_DATE',
  EVENT_DATE: 'EVENT_DATE',
  FEEDBACK_DATE: 'FEEDBACK_DATE',
}

export const FeedbackRating = {
  BLACKLIST: 'BLACKLIST',
  NO: 'NO',
  REGULAR: 'REGULAR',
  YES: 'YES',
  STRONG_YES: 'STRONG_YES',
}

export const TagCategory = {
  EMPLOYEE_ACTION: 'EMPLOYEE_ACTION',
  TECHNOLOGY: 'TECHNOLOGY',
  LANGUAGE: 'LANGUAGE',
}

export const PayrollStatusTagColor = {
  [PayrollStatus.PAID]: 'green',
  [PayrollStatus.READY_TO_PAY]: 'purple',
  [PayrollStatus.PARTIALLY_PAID]: 'blue',
  [PayrollStatus.TO_REVIEW]: 'orange',
  [PayrollStatus.ERROR]: 'red'
}

export const PayrollConceptType = {
  RECRUITMENT_COMMISSION: 'RECRUITMENT_COMMISSION',
  CONTRACT_CONCEPT: 'CONTRACT_CONCEPT',
  MANUAL_CONCEPT: 'MANUAL_CONCEPT',
  SALES_COMMISSION: 'SALES_COMMISSION'
}

export const PayrollDetailCategory = {
  ...BusinessUnit,
  STAFF: 'STAFF',
};

export const CurrencySource = {
  MANUAL: 'MANUAL',
  EL_CRONISTA: 'EL_CRONISTA',
}

export const ElCronistaKey = {
  BLUE: 'ARSB',
  BNA: 'ARS',
  CCL: 'ARSCONT',
  MEP: 'ARSMEP',
}

export const DolarCalculated = {
  SELL_AVERAGE: 'SELL_AVERAGE',
  BNA_AVERAGE: 'BNA_AVERAGE',
  BLUE_AVERAGE: 'BLUE_AVERAGE',
  CONVERSION: 'CONVERSION',
  CCL_SELL: 'CCL_SELL',
  MEP_SELL : 'MEP_SELL',
}

export const CurrencyExchangeRateType = {
  SELL: 'SELL',
  BUY: 'BUY',
}

export const JobProposalState = {
  PRE_OFFER: 'PRE_OFFER',
  OFFER: 'OFFER',
}

export const JobProposalStatus = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  ON_REVIEW: 'ON_REVIEW',
}

export const JobProposalLanguage = {
  ENGLISH: 'ENGLISH',
  SPANISH: 'SPANISH',
}

export const JobProposalContractTerm = {
  DEFINED: 'DEFINED',
  ONGOING: 'ONGOING',
}

export const CandidateChoice = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  REVIEW: 'review',
};

export const BenefitValueType = {
  INTEGER: 'int',
  FLOAT: 'float',
  TEXT: 'text',
  ENTITY: 'entity',
  LIST: 'list',
}

export const BenefitCategory = {
  GENERIC: 'GENERIC',
  GIFT: 'GIFT',
  HEALTHCARE: 'HEALTHCARE',
  VACATION: 'VACATION',
  TRAINING: 'TRAINING',
  EQUIPMENT: 'EQUIPMENT',
  CUSTOM: 'CUSTOM',
}

export const BenefitCategoryTagColor = {
  [BenefitCategory.GENERIC]: 'default',
  [BenefitCategory.GIFT]: 'gold',
  [BenefitCategory.HEALTHCARE]: 'blue',
  [BenefitCategory.VACATION]: 'purple',
  [BenefitCategory.TRAINING]: 'geekblue',
  [BenefitCategory.EQUIPMENT]: 'cyan',
  [BenefitCategory.CUSTOM]: 'red',
}

export const CountryRegion = {
  NORTH_AMERICA: "NORTH AMERICA",
  CENTRAL_AMERICA: "CENTRAL AMERICA",
  LATAM: "LATAM",
  ARGENTINA: "ARGENTINA",
  EUROPE: "EUROPE",
  OCEANIA: "OCEANIA",
}

export const ParameterEnum = {
  RATE_HOURS_TO_TREES: "rate_hours_to_trees",
  STRUCTURE_COST_PERCENTAGE: "structure_cost_percentage",
  JOB_PROPOSAL_PREOFFER_TEMPLATE: 'JOB_PROPOSAL_PREOFFER_TEMPLATE',
  JOB_PROPOSAL_OFFER_TEMPLATE: 'JOB_PROPOSAL_OFFER_TEMPLATE',
  DAYS_FOR_RELEASE_SOON: 'DAYS_FOR_RELEASE_SOON',
  PAYROLL_FIXED_AMOUNTS: 'PAYROLL_FIXED_AMOUNTS',
  GROSS_MARGIN_APPROVAL_LIMIT: 'GROSS_MARGIN_APPROVAL_LIMIT',
  LANGUAGE_ASSESSMENT_DEADLINES: 'LANGUAGE_ASSESSMENT_DEADLINES',
  EMPLOYEE_CONTACT_RELATION_TYPE: "EMPLOYEE_CONTACT_RELATION_TYPE",
  SALES_COMMISSION_POLICY_DEFAULTS: "SALES_COMMISSION_POLICY_DEFAULTS"
}

export const ParameterTypeEnum = {
  STRING: 'string',
  INT: 'int',
  FLOAT: 'float',
  JSON: 'json',
}

export const RecruitmentPriority = {
  URGENT: 'Urgent',
  OPTIMAL: "Optimal",
  MEDIUM: "Medium",
  LOW: "Low",
  CLOSED: "Closed",
  ONHOLD: "On hold"
}

export const RecruitmentCustomerPriority = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  ONHOLD: "On hold",
  CLOSED: "Closed"
}

export const RecruitmentCloseReason = {
	COVERED_EXOMINDSET: "Position filled by Exomindset",
	COVERED_CLIENT_PROVIDER: "Covered by the client or another vendor",
	CANCELED_CLIENT: "Position canceled by the client",
	ON_HOLD: "Position On Hold",
	NO_RESPONSE_CLIENT: "Lack of response from the client",
	LOST_OPPORTUNITY: "Sales opportunity lost"
}

export const NoteRelatedEntity = {
  DEPARTMENT: "DEPARTMENT",
  CUSTOMER: "CUSTOMER",
  JOB_POSITION: "JOB_POSITION",
}

export const StagesJobPosition = {
  DATABASE: "Database / Contact in Future",
  RE_ENGAGE: "Re-Engage",
  BENCH: "Bench",
  CONTACTED: "Contacted",
  INITIAL_QUALIFICATION: "Initial Qualification",
  FIRST_INTERVIEW: "First Interview (HR)",
  SECOND_INTERVIEW: "Second Interview (Technical)",
  SCREENING: "Screening (CV)",
  WAITING_PROJECT: "Waiting for Project",
  PRESENTED_TO_CUSTOMER: "Presented to Customer",
  WAITING_CUSTOMER_INTERVIEW: "Waiting Customer Interview",
  DOING_CHALLENGE: "Doing Challenge",
  CUSTOMER_TECHNICAL_INTERVIEW: "Customer Technical Interview",
  WAITING_CUSTOMER_FEEDBACK: "Waiting Customer´s Feedback",
  HIRE: "Hire! Sending Proposal",
  ON_HOLD: "On Hold"
}

export const StagesJobPositionGroup = {
  PHASE_1: 'Suggested Candidates',
  PHASE_2: 'Applicant Exo Journey',
  PHASE_3: 'Applicant Customer Journey'
}

export const CefrLevelEnum = {
  A1: 'A1',
  A2: 'A2',
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  C2: 'C2',
}

export const cefrLevelOptions = Object.keys(CefrLevelEnum).map(key => ({ value: key, label: CefrLevelEnum[key] }))

export const ResultLanguageAssessmentEnum = {
  A1: 'A1',
  A1_A2: 'A1-A2',
  A2: 'A2',
  A2_B1: 'A2-B1',
  B1: 'B1',
  B1_B2: 'B1-B2',
  B2: 'B2',
  B2_C1: 'B2-C1',
  C1: 'C1',
  C1_C2: 'C1-C2',
  C2: 'C2',
}

export const resultAssessmentOptions = Object.entries(ResultLanguageAssessmentEnum)
  .map(([_, value]) => ({ value, label: value }))

export const ApplicantApplicationStatusEnum = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED'
}

export const GrossMarginStatus = {
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED'
}

export const ApplicantProcessStatusEnum = {
  IN_PROGRESS: 'IN PROGRESS',
  FINISHED: 'FINISHED',
  REFUSED: 'REFUSED'
}

export const ApplicantFeedbackTypeEnum = {
  HR: 'HR',
  PROCESS_STAGE: 'PROCESS_STAGE'
}

export const RecruitmentPercentageStatusEnum = {
  PENDING: 'PENDING',
  REVIEW: 'REVIEW',
  CONFIRMED: 'CONFIRMED'
}

export const PolicyCalculationBasis = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  GROSS_MARGIN: 'GROSS_MARGIN',
  FIXED_AMOUNT_BY_GROSS_MARGIN: 'FIXED_AMOUNT_BY_GROSS_MARGIN'
}

export const PolicyTrigger = {
  CREATE_STAGE_FEEDBACK: 'CREATE_STAGE_FEEDBACK',
  CREATE_HR_FEEDBACK: 'CREATE_HR_FEEDBACK',
  PROCESS_FINISHED: 'PROCESS_FINISHED',
}

export const PolicySettlementDate = {
  APPLICANT_START_DATE: 'APPLICANT_START_DATE',
  EVENT_DATE: 'EVENT_DATE',
  FEEDBACK_DATE: 'FEEDBACK_DATE'
}

export const PolicySettlementPeriodicity = {
  MONTHS: 'MONTHS',
  DAYS: 'DAYS',
  BUSINESS_DAYS: 'BUSINESS_DAYS'
}

export const CommissionAmountType = { FIXED: 'FIXED', SALARY_PERCENTAGE: 'SALARY_PERCENTAGE' }

export const ModuleEnum = {
  TIMESHEET: 'timesheet',
  BILLING: 'billing',
  PAYROLL: 'payroll',
  EMPLOYEE: 'employee',
  PROJECT: 'project',
  CUSTOMER: 'customer',
  ASSGINMENT: 'assignment',
  APPLICANT: 'applicant',
  APPLICANT_PROCESS: 'applicant_process',
  TREE_DONATION: 'tree_donation',
  JOB_PROPOSAL: 'job_proposal',
  JOB_POSITION: 'job_position',
  ONBOARDING: 'onboarding'
}

export const EntityTypeEnum = {
  TREE_DONATION: 'TreeDonationEntity',
  EMPLOYEE: 'EmployeeEntity',
  BILLING: 'BillingEntity'
}

export const TimesheetStatusEnum = {
  OPEN: 'OPEN',
  WAITING_REVIEW: 'WAITING_REVIEW',
  APPROVED: 'APPROVED',
}
export const MethodFieldType = {
  INT: "INT",
  STRING: 'STRING',
  BANK: 'BANK',
  BOOLEAN: 'BOOLEAN',
  PSP: 'PSP',
  LIST: 'LIST',
  DYNAMIC_CODE: 'DYNAMIC_CODE',
  FLOAT: 'FLOAT',
  ENUM: 'ENUM'
}

export const RuleTypeEnum = {
  LENGTH: "LENGTH",
  MIN: 'MIN',
  MAX: 'MAX',
  BOOLEAN: 'BOOLEAN',
  ENUM: 'ENUM',
  PATTERN: 'PATTERN',
  VALIDATOR: 'VALIDATOR'
}

export const timeSpanEnum = {
  ALL_DAY: 'all_day',
  HOURS: 'hours'
}

export const DayTemplateTypeEnum = {
  FIXED: 'fixed',
  MOBILE: 'mobile'
}

export const AddressTypeEnum = {
  RESIDENCIAL: 'RESIDENCIAL',
  LEGAL: 'LEGAL'
}

export const SCommissionTypes = {
  BILLING_PAYMENT: 'BILLING PAYMENT'
}

export const SalesCommissionStatus = {
  PENDING: 'PENDING',
  TO_REVIEW: 'TO REVIEW',
  READY_TO_PAY: 'READY TO PAY',
  PARTIALLY_PAID: 'PARTIALLY PAID',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED'
}

export const RecruitmentEntityEnum = { ENGAGER: 'recruiter', SOURCE: 'source' }

export const AssetAssignmentStatusEnum = {
  ACTIVE: 'ACTIVE',
  PENDING_DELIVERY: 'PENDING_DELIVERY',
  PENDING_RETURN: 'PENDING_RETURN',
  FINISHED: 'FINISHED',
}

export const AssigmentStatusTagColor = {
  [AssetAssignmentStatusEnum.ACTIVE]: 'green',
  [AssetAssignmentStatusEnum.PENDING_RETURN]: 'blue',
  [AssetAssignmentStatusEnum.PENDING_DELIVERY]: 'blue',
  [AssetAssignmentStatusEnum.FINISHED]: 'red'
}

export const AvailableEnum = {
  true: {
    title: "AVAILABLE",
    color: "green"
  },
  false: {
    title: "UNAVAILABLE",
    color: "volcano"
  }
}

export const FinancialEntityTypeEnum = {
  PSP: 'PSP',
  BANK: 'BANK',
}